import React from 'react';
import { Link } from 'react-router-dom';


const Hero2 = () => {
  return (
    <div class="hero min-h-max w-screen bg-gray-100">

      <div class="hero-content text-center">

        <div class="max-w-screen">
                    <br/>
            <br/>
            <h1 class="text-2xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-center text-gray-800 font-black leading-7 md:leading-10">
            Start Using RecapGolf in 3 Easy Steps
            </h1>
            <br/>
            <br/>
            <br/>
          <ul class="steps steps-vertical lg:steps-horizontal text-2xl">
            <li class="step step-primary">Export Your Sim Data</li>
            <li class="step step-primary">Upload File</li>
            <li class="step step-primary">View Trends</li>
          </ul>
          <br/>
          <br/>
                    <br/>
          <br/>
          <Link to="/demopage">
          <button class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-700 bg-primary transition duration-150 ease-in-out hover:bg-green-600 lg:text-xl lg:font-bold  rounded text-white px-4 sm:px-10 border border-green-700 py-2 sm:py-4 text-sm">Try Live Demo Now </button>
          </Link>
      </div>
    </div>
    </div>
  );
};

export default Hero2;