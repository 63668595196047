import React, { useState, useEffect } from 'react';
import FileUpload from './fileUpload';
import {
  logCsvUploaded,
  logClubTypeSelected,
  logGenerateInsightsClicked,
  logDataReceived
} from './firebaseAnalytics';





const DemoPage = () => {
    const [rawCSV, setRawCSV] = useState('');
    const [response, setResponse] = useState("");
    const [metricsData, setMetricsData] = useState([]);
    const [selectedClubType, setSelectedClubType] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleCSVProcessed = (csvString) => {
        setRawCSV(csvString);
        // console.log("Processed CSV Data for API:", csvString);  // Log the CSV data that will be sent to the API
        logCsvUploaded();
    };



    const handleButtonClick = async () => {
        if (!rawCSV || rawCSV.trim() === '') {
            setErrorMessage("Please upload a file before proceeding.");

            setTimeout(() => {
                setErrorMessage(null);
            }, 5000);

            return;
        }
        const myHeaders = new Headers();
        setIsLoading(true);  // Start the loading state
        myHeaders.append("Content-Type", "application/json");

        console.log("Sending the following CSV data:", rawCSV);
        // Count the number of records (subtracting one for the header)
        const recordCount = rawCSV.split("\n").length - 1;
        logGenerateInsightsClicked(recordCount);
        console.log(`Sending ${recordCount} records.`);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
          "model": "gpt-3.5-turbo-16k",
          "messages": [
            {
              "role": "system",
              "content": "you are a seasoned PGA Golf Coach professional and are focused " +
              "on providing insights and ideas for golfers that provide their launch data for their golf simulator metrics" +
               "The golfer doesn't fully understand the data and you are to help improve their game by making sense of the numbers" +
               "You are to provide insights into Club Face, Club Path, Attack Angle,Face to Path, and Smash Factor based on the data supplied for their" + selectedClubType + "For each of those metrics you picked" +
                "provide a casual definition of the metric" + 
                "1 insight based on their data is about 3 sentences, that includes their average,  that helps answer: what is this datas telling me and why do i care?" + 
                "and one way to improve towards that metric efficiency that is 3-4 sentences long" +
                "The format for each metric you pick:" +
                    "Metric Name: -||-" +
                    "Definition: -||-" +
                    "Insight: -||" +
                    "Improvement: -||-"
            },
            {
              "role": "user",
              "content": rawCSV
          }
          ],
          "temperature": .5,
          "top_p": .5,
          "frequency_penalty": 0,
          "presence_penalty": 0
        }),
        redirect: 'follow'
    };


    try {
            const apiResponse = await fetch("https://cors-proxy.fringe.zone/https://us-central1-golfapp-73e02.cloudfunctions.net/defaultOpenAIRequest", requestOptions);
            const result = await apiResponse.json();
            setResponse(result);
            
            if (result?.choices?.[0]?.message?.content) {
                parseData(result.choices[0].message.content);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    };

    const parseData = (text) => {
        console.log("Raw text to be parsed:", text);  // Log the raw text

        const regex = /Metric Name: (.*?)\nDefinition: (.*?)\nInsight: (.*?)\nImprovement: (.*?)(?=\nMetric Name:|$|\Z)/gs;

        const matches = Array.from(text.matchAll(regex));
        console.log("Matches found:", matches.length, "from text:", text);


        console.log("Parsed matches:", matches);  // Log the parsed matches

        if (matches && matches.length) {
            const metrics = matches.map(match => ({
                metricName: match[1],
                definition: match[2],
                insight: match[3],
                improvement: match[4],
            }));

            setMetricsData(metrics); // Save the parsed metrics data
            logDataReceived(metrics.length);
        }
    };




    return (

    <section className="mb-32 text-gray-800 text-center">
         <br />
         <div class="badge badge-primary badge-outline">Launch Monitors we currently support</div>
        <br />
        <br />


    <ul class="steps steps-horizontal">
      <li data-content="✓" class="step step-primary">R10</li>
      <li data-content="✕" class="step">Uneekor</li>
      <li data-content="✕" class="step">FlightScope</li>
    </ul>
 <br />
        <br />
        
                <div className="flex items-center justify-center">
        <FileUpload 
            onCSVProcessed={handleCSVProcessed} 
            onClubTypeSelected={setSelectedClubType}  // Step 4: Pass down the setSelectedClubType function
        />
    </div>

        <br />

        <button className="btn btn-primary" onClick={handleButtonClick}>
            Generate Insights
        </button>


        {errorMessage && (
            <div className="alert alert-error">
                {errorMessage}
            </div>
        )}



        {isLoading && (
            <div class="alert alert-success flex flex-col items-center justify-center space-y-4">
                <div class="flex items-center space-x-4">
                    <span class="loading loading-spinner text-primary"></span>
                            <br />
                                    <br />
                    <p class="font-black font-normal text-2xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-center">Processing your data</p>
                    <span class="loading loading-spinner text-primary"></span>
                </div>
            </div>
        )}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-10 "> 
            {metricsData && metricsData.length > 0 && metricsData.map((metric, index) => (
                <div key={index} className="relative h-auto">
                    
                    {/* Front of the card */}
                    <div className=" h-full shadow-2xl">
                        <a href="#" className="block bg-white overflow-hidden rounded-lg border border-gray-100 p-4 sm:p-6 lg:p-8">
                            <span className="rounded absolute inset-x-0 bottom-0 h-3 bg-gradient-to-r from-green-600 via-green-400 to-green-600"></span>
                            <div className="mt-4">
                                <h3 className="text-lg font-bold text-gray-900 sm:text-xl">Definition: {metric.metricName} </h3>
                                <p className="max-w-[90ch] text-sm text-gray-500">{metric.definition}</p>
                            </div>
                            <div className="mt-4">
                                <h3 className="text-lg font-bold text-gray-900 sm:text-xl">Key Insight</h3>
                                <p className="max-w-[90ch] text-sm text-gray-500">{metric.insight}</p>
                            </div>
                            <div className="mt-4">
                                <h3 className="text-lg font-bold text-gray-900 sm:text-xl">Improvement</h3>
                                <p className="max-w-[90ch] text-sm text-gray-500">{metric.improvement}</p>
                            </div>
                        </a>
                    </div>
                </div>
            ))}
        </div>

    </section>
    );
};

export default DemoPage;