import { createContext } from 'react';

const CSVContext = createContext({
    csvData: [],
    setCsvData: () => {},
    rawCsv: '',
    setRawCsv: () => {},
});

export default CSVContext;
