import React from 'react';
import { Link } from 'react-router-dom';
import img from './ss.png'
import img1 from './db.png'


const Mocks = () => {
  return (


<dh-component>
  <div class="">
    <div class="container mx-auto flex flex-col items-center">
      <div class="w-11/12 sm:w-2/3 lg:flex justify-center items-center flex-col  mb-5 sm:mb-10">
        <p class="mt-5 sm:mt-10 text-blackfont-normal text-center text-2xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-5xl">
          RecapGolf allows you to say goodbye to messy spreadsheets and hello to intuitive analysis
        </p>
      </div>
    </div>
    <div class="flex justify-center items-center">
    <div class="card lg:card-side bg-base-100 shadow-xl">
            <div class="card card-compact w-96 bg-base-100 shadow-xl">
              <figure><img src={img}  alt="Shoes" /></figure>
              <div class="card-body justify-center items-center">
                <h2 class="card-title">Simulator Export</h2>
              </div>
            </div>
            <div class="px-8 mt-5 sm:mt-10  flex justify-center items-center">
                <svg fill="427e09" width = "10vw" height="auto" stroke="green" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"></path>
                </svg>
            </div>

                      <div class="card card-compact w-96 bg-base-100 shadow-xl">
              <figure><img src={img1}  alt="Shoes" /></figure>
            <div class="card-body justify-center items-center">
                  <h2 class="card-title">RecapGolf Analysis</h2>
            </div>
          </div>
    </div>
    </div>

    </div>
</dh-component>


  );
};

export default Mocks;