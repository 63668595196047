import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { app, analytics } from '../firebaseConfig';

const logCsvUploaded = () => {
  logEvent(analytics, 'csv_uploaded');
};

const logClubTypeSelected = (clubType) => {
  logEvent(analytics, 'club_type_selected', {
    club_type: clubType
  });
};

const logGenerateInsightsClicked = (recordCount) => {
  logEvent(analytics, 'generate_insights_clicked', {
    record_count: recordCount
  });
};

const logDataReceived = (metricsCount) => {
  logEvent(analytics, 'data_received', {
    metrics_count: metricsCount
  });
};

export {
  logCsvUploaded,
  logClubTypeSelected,
  logGenerateInsightsClicked,
  logDataReceived
};
