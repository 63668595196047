import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { doc, setDoc } from "firebase/firestore"; 
import { db, analytics } from '../firebaseConfig.js';


const Hero = () => {

  const [email, setEmail] = useState('');
  const [showNotification, setShowNotification] = useState(false); // Step 1



  const handleSignUp = async (event) => {
    event.preventDefault();
    
    if (email) {
      setShowNotification(true); // Display the custom notification
      setTimeout(() => {
        setShowNotification(false); // Hide the notification after 3 seconds
      }, 3000);
      
      try {
        await setDoc(doc(db, "emailAddresses", email), { email });
      } catch (error) {
        console.error("Error writing to Firestore:", error);
      } finally {
        setEmail('');
      }
    } else {
      // Comment out or remove the alert
      // alert('Please enter a valid email address');
    }
  };


  return (




<dh-component>
  <div class="bg-gray-100">
    <div class="container mx-auto flex flex-col items-center py-12 sm:py-24">
      <div class="w-11/12 sm:w-2/3 lg:flex justify-center items-center flex-col  mb-5 sm:mb-10">
        <h1 class="text-2xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl text-center text-gray-800 font-black leading-7 md:leading-10">
          Convert your 
          <span class="text-primary"> Launch Monitor Data </span>
          into Customized Insights
        </h1>
        <p class="mt-5 sm:mt-10 text-gray-400 font-normal text-center text-2xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-5xl">Unlock your simulator stats using AI that is tailored to you </p>
      </div>
                  {showNotification && (
  <div class="alert alert-success flex flex-col items-center justify-center space-y-4">
    <div class="flex items-center space-x-4">
        <p class="font-black font-normal text-2xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-center">Thank You!</p>
    </div>
  </div>
)}
      <div class="flex justify-center items-center">
        <div class="mx-auto mt-8 max-w-xl">
      <form action="#" onSubmit={handleSignUp} class="sm:flex sm:gap-4">
      <div class="sm:flex-1">
        <label for="email" class="sr-only">Email</label>

        <input
          type="email"
          placeholder="Email address"
          value={email} // Link the input to the email state
          onChange={e => setEmail(e.target.value)} // Update the state as user types
          class="w-full rounded-md border-gray-200 bg-white p-3 text-gray-700 shadow-sm transition focus:border-white focus:outline-none focus:ring focus:ring-white-400"
        />
      </div>

        <button
          type="submit"
          class="group mt-4 flex w-full items-center justify-center gap-2 rounded-md btn-primary px-5 py-3 text-white transition focus:outline-none focus:ring focus:ring-white-400 sm:mt-0 sm:w-auto"
        >
          <span class="text-sm font-medium"> Early Access Request </span>

          <svg
            class="h-5 w-5 rtl:rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 8l4 4m0 0l-4 4m4-4H3"
            />
          </svg>
        </button>
      </form>
    </div>
      </div>
    </div>

  </div>
</dh-component>

  );
};

export default Hero;