import React from 'react';
import { Link } from 'react-router-dom';
import img from './rg.png'


const Navbar = () => {
  return (
    <header class="sticky top-0 z-50">
    <div class="navbar bg-base-100">
      <div class="flex-1">
        <Link to="/">
        <a class=" normal-case text-xl "><img src={img} width="200" 
     height="500"></img></a>
        </Link>
      </div>
      <div class="flex-none">
        <Link to="/demopage">
          <button class="btn  btn-primary">
            Live Demo
          </button>
        </Link>
      </div>
    </div>
    </header>
  );
};

export default Navbar;