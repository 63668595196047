import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CSVContext from './CSVContext';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Pic from './components/Pic';
import Features from './components/Features';
import Hero2 from './components/Hero2';
import Footer from './components/Footer';
import DemoPage from './components/DemoPage';
import Mocks from './components/Mocks';


function App() {
  const [csvData, setCsvData] = useState([]);
  const [rawCsv, setRawCsv] = useState('');  // Add this line to manage raw CSV state

  return (
    <CSVContext.Provider value={{ csvData, setCsvData, rawCsv, setRawCsv }}>
      <Router>
        <Navbar />
         <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <Mocks />
              <Features />
              <Hero2 />
            </>
          } />
          <Route path="/demopage" element={<DemoPage />} />
        </Routes>
      </Router>
    </CSVContext.Provider>
  );
}
export default App;
