// Import necessary Firebase libraries
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCMnjL0JUsEnJ84ciRujstORRBfxaDYB2I",
  authDomain: "golfapp-73e02.firebaseapp.com",
  projectId: "golfapp-73e02",
  storageBucket: "golfapp-73e02.appspot.com",
  messagingSenderId: "838230900681",
  appId: "1:838230900681:web:b1752c2dd0dda5c084f218",
  measurementId: "G-H5JNNT4FCT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Analytics
const analytics = getAnalytics(app);

// Export the Firestore database instance
export { db,app, analytics };
