import React, { useState, useEffect  } from 'react';
import Papa from 'papaparse';
import { db } from '../firebaseConfig';
import { collection, addDoc ,writeBatch, doc } from 'firebase/firestore';

const FileUpload = ({ onCSVProcessed }) => {
    const [uniqueClubTypes, setUniqueClubTypes] = useState([]);
    const [selectedClubType, setSelectedClubType] = useState(null);
    const [csvData, setCsvData] = useState(null);
    const [isValid, setIsValid] = useState(false);




    const readFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const writeToFirestoreAsSingleDocument = async (data) => {
        const collRef = collection(db, 'userUploads'); // Replace with your collection name

        // Structure the document as you want it to appear in Firestore
        const documentData = {
            csvData: data // Array of row objects
            // You can add more fields here if you want
        };

        try {
            // Add a new document with a generated id.
            const docRef = await addDoc(collRef, documentData);
            console.log('Document written with ID: ', docRef.id);
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    const extractUniqueClubTypes = (data) => {
        const clubTypes = data.map(row => row["Club Type"]);
        const uniqueTypes = [...new Set(clubTypes)].filter(Boolean);  // this filter will remove any falsy values, including empty strings
        setUniqueClubTypes(uniqueTypes);
    };


    const processCSV = (data) => {
        // Filter data based on selected Club Type
        const filteredResult = selectedClubType ? data.filter(row => row["Club Type"] === selectedClubType) : data;
        
        console.log(filteredResult);

        // Remove the 2nd row (index 1)
        filteredResult.splice(0, 1);

        // Filter out only the desired columns
        const filteredData = filteredResult.map(row => ({
            "Club Path": row["Club Path"],
            "Smash Factor": row["Smash Factor"],
            "Attack Angle": row["Attack Angle"],
            "Club Face": row["Club Face"],
            "Club Type": row["Club Type"],
            "Face to Path": row["Face to Path"]
        }));

        const formattedDataForFirestore = filteredData.map(row => ({
            clubPath: row["Club Path"],
            smashFactor: row["Smash Factor"],
            attackAngle: row["Attack Angle"],
            clubFace: row["Club Face"],
            clubType: row["Club Type"],
            faceToPath: row["Face to Path"]
        }));

          // Upload to Firestore
        writeToFirestoreAsSingleDocument(formattedDataForFirestore);

        const desiredHeaders = ["Club Path","Club Type", "Smash Factor", "Attack Angle", "Club Face", "Face to Path"];

        const headerString = desiredHeaders.join(",");
        const dataStrings = filteredData.map(row => 
            desiredHeaders.map(header => row[header]).join(",")
        );
        const csvContent = [headerString, ...dataStrings].join(" ");

        const csvString = "\"" + csvContent + "\"";

        onCSVProcessed(csvString);
    };



    // Remove the call to processCSV from handleFileChange.
    const handleFileChange = (event) => {
        setCsvData(null); // Reset previous data
        setIsValid(false); 
        const file = event.target.files[0];
        if (!file) {
            alert("Please select a file.");
            return;
        }
        if (file.name.split(".").pop().toLowerCase() !== "csv") {
            alert("Please upload a .csv file.");
            return;
        }

        if (file.size === 0) {
            alert("The uploaded CSV file is empty. Please upload a valid file.");
            return;
        }

        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => {
            // Parse the CSV
            const result = Papa.parse(reader.result, {
                header: true,
                skipEmptyLines: true
            });

            if (!result.data || result.data.length === 0) {
                alert("The uploaded CSV file doesn't contain any data. Please upload a valid file.");
                return;
            }

            // Extract unique Club Types
            extractUniqueClubTypes(result.data);

            // Store the parsed data
            setCsvData(result.data);
            setIsValid(true);
        };
    };

    // Now, call processCSV only when a club type is selected.
    const handleClubTypeChange = (event) => {
        setSelectedClubType(event.target.value);
    };

    useEffect(() => {
         console.log("useEffect triggered. csvData:", csvData, "selectedClubType:", selectedClubType, "isValid:", isValid);
        if (csvData && selectedClubType && isValid) {
            processCSV(csvData);
        }
    }, [csvData, selectedClubType, isValid]);


    return (
        <div>
            <div className="form-control w-full max-w-xs">
                <label className="label">
                    <span className="label-text">Pick a file</span>
                </label>
                <input type="file" className="file-input file-input-bordered w-full max-w-xs" onChange={handleFileChange} />
            </div>

            {uniqueClubTypes.length > 0 && (
                <div className="mt-4">
                    {uniqueClubTypes.map(clubType => (
                        <label key={clubType}>
                            <input
                                type="radio"
                                name="clubType"
                                class="radio radio-primary"
                                value={clubType}
                                checked={selectedClubType === clubType}
                                onChange={handleClubTypeChange}
                            />
                            {clubType}
                        </label>
                    ))}
                </div>
            )}
        </div>
    );
};

export default FileUpload;
